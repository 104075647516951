<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Kullanıcı Ekle",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Kullanıcı Ekle",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Kullanıcı Ekle",
          active: true,
        },
      ],
      values: {
        name: "",
        email: "",
        password: "",
        authority: []
      },
      statusOptions: [
        { value: 1, text: "Admin" },
        { value: 2, text: "Müdür" },
        { value: 3, text: "Muhasebe" },
        { value: 4, text: "Rezervasyon" },
        { value: 5, text: "Araç Şöförü" },
      ],
    };
  },
  methods: {
    submitNewHotel() {
      const api_url = process.env.VUE_APP_BASEURL + "/register";
      const data = {
        name: this.values.name,
        email: this.values.email,
        password: this.values.password,
        authority: JSON.stringify(this.values.authority),
      };
      axios
        .post(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$router.push("/users");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-9">
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="submitNewHotel">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="model">İsim Soyisim</label>
                    <input
                      v-model="values.name"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="email">Mail Adresi</label>
                    <input
                      v-model="values.email"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="password">Şifre</label>
                    <input
                      v-model="values.password"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="status">Yetki:</label>
                      <b-form-checkbox-group v-model="values.authority" class="form-control">
                        <b-form-checkbox v-for="(option, index) in statusOptions" :key="index" :value="option.value">
                          {{ option.text }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary px-4">Ekle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
